:root{ --main-color: #F55C78; }

@media (max-width: 991px) {
    .pricing-one__single .pricing-one__btn {
        opacity: 1;
        transform: translateY(50%) translateX(-50%);
    }

    .pricing-one .row > div + div {
        margin-top: 60px;
    }

    .pricing-one .pricing-one__icon img {
        width: 80%;
    }

    .pricing-one .pricing-one__icon {
        width: 114px;
        height: 124px;
    }

    .pricing-one .pricing-one__icon::before {
        background-size: contain;
    }

}

.pricing-one .pricing-one__single {
    box-shadow: 0px 10px 40px 10px rgba(25, 71, 174, 0.1);
}

.pricing-one .pricing-one__single .pricing-one__btn {
    box-shadow: 0px 20px 40px 5px rgba(25, 71, 174, 0.14);
}

.pricingTable{
    color: #fff;
    background: #F9F9FA;
    font-family: 'Ubuntu', sans-serif;
    text-align: center;
    padding: 0 0 30px;
    border-radius: 30px;
}

.pricingTable .pricingTable-header{
    background-color: var(--main-color);
    padding: 30px 0 70px;
    border-radius: 30px 30px 0 0;
    box-shadow: 0 -10px 30px rgba(0,0,0,0.2) inset;
}

.pricingTable .title{
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
}

.pricingTable .price-value{
    color: var(--main-color);
    background: #fff;
    font-size: 45px;
    font-weight: 400;
    line-height: 45px;
    width: calc(100% - 60px);
    padding: 5px 0;
    margin: 0 auto;
    border: 1px solid var(--main-color);
    border-radius: 50px;
    transform: translateY(-30px);
}

.pricingTable .pricing-content{
    padding: 0;
    margin: 0 0 40px;
    list-style: none;
    display: inline-block;
}

.pricingTable .pricing-content li{
    color: #222;
    font-size: 17px;
    text-transform: uppercase;
    line-height: 35px;
    padding: 0 15px;
    margin: 0 0 5px;
    border-bottom: 1px solid #222;
}

.pricingTable .pricing-content li:last-child{ margin: 0; }

.pricingTable .pricingTable-signup a{
    color: #fff;
    background: var(--main-color);
    font-size: 25px;
    font-weight: 500;
    line-height: 25px;
    text-transform: uppercase;
    width: calc(100% - 60px);
    padding: 12px 0;
    border-radius: 15px;
    display: inline-block;
    transition: all 0.3s ease;
}

.pricingTable .pricingTable-signup a:hover{
    letter-spacing: 3px;
    text-shadow: -2px 2px 3px rgba(0, 0, 0, 0.6);
}

.pricingTable.blue{ --main-color: #239EFE; }
.pricingTable.purple{ --main-color: #744EA5; }

@media only screen and (max-width: 990px){
    .pricingTable{ margin: 0 0 40px; }
}
