.highlight {
    color: var(--thm-base);
    font-weight: 500;
}

.banner-heading h3{
    font-size: 60px;
}

.banner-one .banner-one__content p:nth-child(1) {
    margin-top: 40px;
    margin-bottom: 22px;
}

.banner-one .banner-one__content p:nth-child(2) {
    font-weight: normal;
    font-size: 22px;
}

@media (max-width: 1199px) {
    .banner-one .banner-one__image > img {
        display: initial;

    }

    .banner-one .banner-one__image {
        bottom: -150px;
        z-index: -1;
    }

    .banner-one .banner-one__content {
        padding-right: 200px;
    }

}

@media (max-width: 767px) {
    .banner-one .banner-one__content {
        padding-right: 0px;
    }

    .banner-one .banner-one__content h3 {
        font-size: 35px;
    }

    .banner-one .banner-one__image {
        right: -185px;
        bottom: -190px;
        scale: .6;
    }

    .banner-one .banner-one__image .banner-one__image-1 {
        right: 20px;
    }

}
