
@media (max-width: 1199px) {
    .about-two .about-two__images {
        scale: .8;
    }

    .about-two__image-2 {
        left: calc(50% - 300px);
    }
}

@media (max-width: 767px) {
    .about-two .about-two__images {
        scale: 1;
    }

    .about-two .about-two__images .about-two__image-1 {
        width: 55%;
        right: -34px;
    }

    .about-two .about-two__images .about-two__image-2 {
        left: initial;
    }

    .about-two .about-two__images .about-two__image-3 {
        width: 22%;
        left: -30px;
    }
}
