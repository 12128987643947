html,
body,
#root {
    overflow-x: clip;
}

input:disabled,
button:disabled {
    opacity: .5;
}



