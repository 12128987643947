.logo-box img {
    width: 190px;
}

.main-nav-one__btn {
    transition: all 500ms ease 300ms, background 200ms ease 0ms;
}

.main-nav-one__btn:hover {
    background: transparent;
    transition: all 600ms ease 0ms, background 200ms ease;
}

.main-nav-one__btn::before {
    z-index: -1;
}

.main-nav-one__btn + .main-nav-one__btn {
    margin-left: 10px;
}


.side-menu__toggler {
    --hamburger-outline: #4463;
    transition: all 0.3s ease;
    color: white;
    rotate: 0deg;
    position: fixed;
    top: 35px;
    right: 37px;
    cursor: pointer;
    z-index: 1001;
    text-shadow:
        -1px -1px 0 var(--hamburger-outline),
         1px -1px 0 var(--hamburger-outline),
        -1px  1px 0 var(--hamburger-outline),
         1px  1px 0 var(--hamburger-outline);
}

.side-menu__toggler.open {
    rotate: 180deg;
}



div.hamburger-nav__main-navigation {
    display: block;
}

div.hamburger-nav__navigation-box {
    display: block;
    pointer-events: none;
    position: fixed;
    top: 100px;
    bottom: 40px;
    left: 15px;
    right: 15px;
    box-shadow: 0px 1px 18px 1px #0005;
    background-color: #3345;
    z-index: 1000;
    transition: all 0.45s ease;
    transition-delay: 300ms;
    opacity: 0;
    transform: translateX(-100px);
    border-top:    3px solid var(--thm-base);
    border-bottom: 3px solid var(--thm-base);
    backdrop-filter: blur(16px);
    border-radius: 6px;
}

.hamburger-nav__navigation-box.open {
    display: block;
    pointer-events: auto;
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0ms;
}



.hamburger-nav__navigation-box ul {
    list-style: none;
    margin: 40px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center; /* Center items vertically */
}

.hamburger-nav__navigation-box ul li {
    margin: 8px 0;
}

.hamburger-nav__navigation-box ul li:not(.hamburger-user) > a {
    display: block;
    color: white;
    font-weight: 600;
    font-size: 1.1em;
    color: var(--thm-base);
    transition: color 0.2s ease;
    font-family: var(--heading-font);
    text-align: center;
    text-shadow: 0 0 16px #2249;
}

.hamburger-nav__navigation-box ul li:not(.hamburger-user) > a:hover {
    color: white;
    text-shadow: 0 0 4px #fff3;
}

.hamburger-nav__navigation-box .hamburger-user {
    margin: auto;
    margin-top: 80px;
}



.main-nav__right {
    margin-right: 70px;
}

.main-nav-one .main-nav__main-navigation {
    display: none;
}

.main-nav-one .thm-btn {
    padding: 8px 38px;
}

@media (min-width: 1030px) {
    div.hamburger-nav__main-navigation {
        display: none;
    }

    .main-nav-one .main-nav__main-navigation {
        display: block;
    }

    .main-nav__right {
      margin-right: -10px;
    }

    .main-nav-one .main-nav__navigation-box>li+li {
        margin-left: 28px;
        font-size: 16px;
    }

    .main-nav-one .main-nav__navigation-box>li>a {
        font-size: 16px;
    }
}

@media (min-width: 1120px) {
    .main-nav-one .main-nav__navigation-box>li+li {
        margin-left: 44px;
    }
    .main-nav-one .main-nav__navigation-box>li>a {
        font-size: 16px;
    }
    .main-nav__right {
      margin-right: 0px;
    }
}




@media (max-width: 800px) {
    .side-menu__toggler {
        top: 32px;
        right: 24px;
    }

    .main-nav__right {
        display: none;
    }
}
